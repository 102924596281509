import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { createLocaleTextGetter } from "../utils";
import ActionButton from "../components/utils/ActionButton";

import gsap from "gsap";
import imagesLoaded from "imagesloaded";

import parallaxAnimation from "../utils/parallaxAnimation";

import "../styles/pages/about.scss";
import DescDeskImage from "../images/pages/about/desktop/les_suites_desc_desk.jpg";
import StoryDeskImage from "../images/pages/about/desktop/les_suites_story_desk.jpg";
import OrigineDeskImage from "../images/pages/about/desktop/les_suites_origines_desk.jpg";

import DescMobImage from "../images/pages/about/mobile/les_suites_mobile.jpg";
import StoryMobImage from "../images/pages/about/mobile/les_suites_story_mobile.jpg";
import OrigineMobImage from "../images/pages/about/mobile/les_suites_origines_mobile.jpg";

import HistoryPdf from '../pdf/histoire.pdf'

const AProposPage = ({ data, pageContext }) => {
  const localeTextGetter = createLocaleTextGetter(pageContext.locale);

  let splittedData = [];
  React.useEffect(() => {
    const textAnimation = require("../utils/textAnimation.js").default;
    const intersectionAnimation =
      require("../utils/intersectionAnimation.js").default;
    if (window.innerWidth > 769) {
      let tl = gsap.timeline({});
      tl.set("main", { autoAlpha: 0 });
      imagesLoaded(".bi_a_propos_header", () => {
        splittedData = textAnimation.splitAllText();

        tl.set("main", { autoAlpha: 1 });
        textAnimation.splitTitleApparition(
          splittedData.splittedTitles.find((el) => el.id === "h1").chars,
          tl
        );
        textAnimation.splitBlockApparition(
          splittedData.splittedBlocks.find((el) => el.id === "header").lines
            .lines,
          "-=0.5",
          "word",
          tl
        );
        tl.fromTo(
          ".bi_a_propos_desc picture",
          { autoAlpha: 0, y: 100 },
          {
            autoAlpha: 1,
            y: 0,
            duration: 1,
            ease: "power2.inOut",
            stagger: 0.2,
          },
          1.25
        );
        intersectionAnimation(splittedData);
      });
      parallaxAnimation.imagesParallax();
      parallaxAnimation.blockParallax();
    }
  }, []);

  return (
    <main>
      <Helmet htmlAttributes={{ lang: pageContext.locale }}>
        <title>
          {pageContext.locale === "fr"
            ? "À propos | Les Suites de l'île Barbe"
            : "About | The Suites of Île Barbe"}
        </title>
        <meta
          type="description"
          content={
            pageContext.locale === "fr"
              ? "Situé sur une île privée, au milieu de la Saône, l’hôtel de charme les Suites de l’Ile Barbe prend place au cœur des vestiges d’une ancienne abbaye."
              : "Located on a private island in the middle of the Saône, the charming hotel Les Suites de l'Île Barbe is set in the heart of the remains of a former abbey."
          }
        />
      </Helmet>
      <div className="bi_a_propos_header">
        <h1 data-splitted-title data-s-tag="h1">
          {pageContext.locale === "fr"
            ? "Les Suites de l’Île barbe"
            : "The Suites of Île Barbe"}
        </h1>
        <div className="bi_a_propos_desc">
          <p data-splitted-block data-s-tag="header">
            {pageContext.locale === "fr"
              ? "Situées sur une île privée, au milieu de la Saône, les Suites de l’Ile Barbe prennent place au cœur des vestiges d’une ancienne abbaye"
              : "Located on a private island in the middle of the Saône River, the Suites de l'Île Barbe are set in the heart of the remains of a former abbey"}
          </p>
          <div className="bi_a_propos_content_text">
            <p
              className="p_propos_1"
              data-intersection-animation
              data-i-trigger=".p_propos_1"
              data-i-type="opacity"
              data-i-value="25"
              data-i-duration="1"
              data-i-delay="0.25"
            >
              {pageContext.locale === "fr"
                ? "Un lieu emblématique de la culture lyonnaise. Entourées d’un parc de 3 000 m², c’est un écrin de calme et de douceur situé à 10 minutes du centre ville de Lyon."
                : "An emblematic place of Lyon's culture. Surrounded by a park of 3,000 m², it is a calm and gentle setting located 10 minutes from downtown Lyon."}
            </p>
          </div>
          <picture
            data-parallax="true"
            data-p-direction="bottom"
            data-p-vitesse="0.5"
          >
            <source srcSet="" media="(min-width: 768px)" />
            <source srcSet={DescMobImage} media="(max-width: 767px)" />
            <img src={DescDeskImage} alt="" />
          </picture>
        </div>
        <div className="bi_a_propos_story">
          <picture
            className="picture_propos_1"
            data-parallax="true"
            data-p-direction="bottom"
            data-p-vitesse="-0.5"
            data-intersection-animation
            data-i-trigger=".bi_a_propos_story"
            data-i-type="opacity"
            data-i-value="25"
            data-i-duration="1"
          >
            <source srcSet="" media="(min-width: 768px)" />
            <source srcSet={StoryMobImage} media="(max-width: 767px)" />
            <img src={StoryDeskImage} alt="" />
          </picture>
          <div className="bi_a_propos_story_text">
            <h2
              data-splitted-block
              data-s-tag="apropostitle"
              data-intersection-animation
              data-i-trigger=".bi_a_propos_story"
              data-i-type="text"
            >
              {pageContext.locale === "fr"
                ? "Des suites de charme chargées d’histoire"
                : "Charming suites full of history"}
            </h2>
            <p
              data-splitted-block
              data-s-tag="apropos"
              data-intersection-animation
              data-i-trigger=".bi_a_propos_story"
              data-i-type="text"
              data-i-delay="0.5"
            >
              {pageContext.locale === "fr"
                ? "L’établissement est situé sur les vestiges de l’ancienne église abbatiale Saint Martin - Saint Loup, où restent notamment préservées une crypte et la « salle capitulaire » où se réunissaient les moines de l’Ile Barbe. Fondée au IIIème siècle, l’antique abbaye est devenue au XVIème dépendante du chapitre des chanoines comtes de Lyon, puis a été finalement vendue et dispersée après la Révolution."
                : "The establishment is located on the remains of the old abbey church Saint Martin - Saint Loup abbey church, where a crypt and the “chapter crypt” and the “chapter house” where the monks of Île Barbe used to meet. Founded in the 3rd century, the ancient abbey became dependent on the chapter of the canons and counts of Lyon in the 16th century of Lyon, then was finally sold and dispersed after the Revolution."}
            </p>
            <p
              data-splitted-block
              data-s-tag="apropos2"
              data-intersection-animation
              data-i-trigger=".bi_a_propos_story"
              data-i-type="text"
              data-i-delay="1"
            >
              {pageContext.locale === "fr"
                ? "Aujourd’hui entièrement rénovés, les lieux offrent tout le confort d’un établissement 5 étoiles. L’architecte d’intérieur Jorge Grasso a travaillé dans le respect de l’histoire des lieux, tout en décorant Les Suites dans un style moderne, élégant et singulier."
                : "Now completely renovated, the premises offer all the comforts of a 5-star establishment. Interior designer Jorge Grasso has worked to respect the history of the place, while decorating the suites in a modern, elegant and unique style."}
            </p>
          </div>
        </div>
      </div>
      <section className="bi_a_propos_content">
        <picture
          data-parallax="true"
          data-p-direction="bottom"
          data-p-vitesse="-0.05"
        >
          <source srcSet="" media="(min-width: 768px)" />
          <source srcSet={OrigineMobImage} media="(max-width: 767px)" />
          <img src={OrigineDeskImage} alt="" />
        </picture>
        <div className="bi_a_propos_content_text">
          <div
            data-parallax="true"
            data-p-direction="bottom"
            data-p-vitesse="0.4"
          >
            <h2
              data-splitted-title
              data-s-tag="apropostitlecontent"
              data-intersection-animation
              data-i-trigger=".bi_a_propos_content_text"
              data-i-type="title"
            >
              {pageContext.locale === "fr" ? "Aux origines" : "To the origins"}
            </h2>
            <p
              data-splitted-block
              data-s-tag="aproposcontent"
              data-intersection-animation
              data-i-trigger=".bi_a_propos_content_text"
              data-i-type="text"
              data-i-delay="0.5"
            >
              {pageContext.locale === "fr"
                ? "Sise sur la Saône, l'abbaye de l'Île Barbe était probablement la plus ancienne des Gaules. S'il est historique qu'elle date de la domination romaine, il n'est pas possible d'en préciser la date. La tradition rapporte que l'histoire de l'île commença par des solitaires, réunis ensuite en communauté sur l'initiative d'un certain Longin (d'après les Masures de l'Île Barbe)."
                : "It was probably the oldest of the Gauls. If it is historical that it dates from the Roman domination, it is not possible to say the date. The tradition says that the history of the island began with solitary people, then united in a community on the initiative of a certain Longin (after the Île Barbe measures)."}
            </p>
            <ActionButton
              type="pdf"
              to={HistoryPdf}
              children={
                pageContext.locale.includes('fr') ? "Continuer l'histoire" : "Continue the story"
              }
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default AProposPage;
